import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTheMask from 'vue-the-mask'
import vueClickOutsideElement from 'vue-click-outside-element'
import store from './store'
import ax from 'axios'
import '/public/css/main.css';
import i18n from './i18n'

import {Icon} from 'leaflet'
import axios from "axios";
import VueSweetalert2 from 'vue-sweetalert2';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet/dist/leaflet.css';

window.axios = ax;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(VueTheMask)
Vue.use(vueClickOutsideElement)
Vue.use(VueSweetalert2);


Vue.config.productionTip = false
import 'leaflet/dist/leaflet.css'
import 'sweetalert2/dist/sweetalert2.min.css';





delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + store.getters.token;
    return config;
});

// let hours = 1;
// let now = new Date().getTime();
// let setupTime = localStorage.getItem('setupTime');
// let user = store.getters.cashier

// if (setupTime == null) {
//     localStorage.setItem('setupTime', now)
// } else {
//     if (now - setupTime > hours * 60 * 60 * 1000 && user) {
//         localStorage.clear();
//         localStorage.setItem('setupTime', now);
//         store.dispatch('clearStore').then(() => {
//             window.location.href = "/login";
//         });
//     }
// }




axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        if (!['/not-auth', '/', '/login', '/register', '/register/doctor', '/register/region',
            '/forgot-password', '/new-password', '/password-code', '/privacy-policy', '/terms-of-use', '/not-yet'
        ].includes(router.currentRoute.path)) {
            store.commit('setToken', null)
            window.location.href = "/"
        }
    }
    return Promise.reject(error)
})