import axios from 'axios'

const state = {
    cashier: {},
    cashier_loading: false,
    cashier_errors: {},
    token: '',
    refresh_token: '',
    stats_doctors_filter: {
        doctor_types: [],
        doctor_id: null,
        region: null,
        district: null,
        drugs: [],
    },
    orders: [],
    orders_loading: false,
}

const getters = {
    cashier: state => state.cashier,
    cashier_loading: state => state.cashier_loading,
    cashier_errors: state => state.cashier_errors,
    token: state => state.token,
    refresh_token: state => state.refresh_token,
    stats_doctors_filter: state => state.stats_doctors_filter,
    orders: state => state.orders,
    orders_loading: state => state.orders_loading,
}

const mutations = {
    setCashier(state, cashier) {
        state.cashier = cashier
    },
    setCashierLoading(state, loading) {
        state.cashier_loading = loading
    },
    setCashierErrors(state, errors) {
        state.cashier_errors = errors
    },
    setToken(state, token) {
        state.token = token
    },
    setRefreshToken(state, token) {
        state.refresh_token = token
    },
    setStatsDoctorFilter(state, stats_doctors_filter) {
        state.stats_doctors_filter = stats_doctors_filter
    },
    setOrders(state, orders) {
        state.orders = orders
    },
    setOrdersLoading(state, orders_loading) {
        state.orders_loading = orders_loading
    },
}

const actions = {
    clearStore({commit}) {
        commit('setCashier', {})
        commit('setToken', '')
    },
    async checkLogin({commit}, data) {
        commit('setCashierErrors', {})
        try {
            let response = await axios.post('/api/cashier/check-login', data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setCashierErrors', error.response.data.errors)
            return false
        }
    },
    async logout({commit}) {
        commit('setCashierLoading', true)
        commit('setCashierErrors', {})
        try {
            let response = await axios.post('/api/logout', {})
            commit('setCashierLoading', false)
            commit('setToken', '')
            commit('setCashier', {})

            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setCashierLoading', false)
            commit('setCashierErrors', error.response.data.errors)
            commit('setToken', '')
            commit('setCashier', {})
            return false
        }
    },
    async check_me({commit}) {
        commit('setCashierLoading', true)
        commit('setCashierErrors', {})
        try {
            let response = await axios.get('/api/cashier/me')
            commit('setCashierLoading', false)
            commit('setCashier', response.data.data)

            return {
                ok: true
            }
        } catch (error) {
            commit('setCashierLoading', false)
            if (error.response.data && error.response.data.errors)
                commit('setCashierErrors', error.response.data.errors)
            return {ok: false, status: error.response.status}
        }
    },
    async impersonate({commit}) {
        commit('setCashierLoading', true)
        commit('setCashierErrors', {})
        try {
            let response = await axios.post('/api/cashier/impersonate')
            commit('setCashierLoading', false)
            if (response.data.ok) {
                commit('setCashier', response.data.user)
                commit('setToken', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                return true
            } else {
                return false
            }
        } catch (error) {
            commit('setCashierLoading', false)
            if (error.response.data && error.response.data.errors)
                commit('setCashierErrors', error.response.data.errors)
            return false
        }
    },
    async login({commit}, data) {
        commit('setCashierLoading', true)
        commit('setCashierErrors', {})
        try {
            let response = await axios.post('/api/cashier/login', data)
            commit('setCashierLoading', false)
            if (response.data.ok) {
                commit('setCashier', response.data.user)
                commit('setToken', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setCashierLoading', false)
            commit('setCashierErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}