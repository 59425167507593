import Vue from 'vue'
import Vuex from 'vuex'
import cashier from './cashier'
import patient from './patient'
import helpers from './helpers'
import shift from './shift'

import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'

const ls = new SecureLS({isCompression: false});
Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        shift,
        helpers,
        patient,
        cashier,
    },
    state: {
        title: 'Regions',
        storage: process.env.VUE_APP_STORAGE,
    },
    getters: {
        title: state => state.title,
        storage: state => state.storage
    },
    mutations: {
        setTitle(state, title) {
            state.title = title
        }
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })],
})


