const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'base',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/main',
        name: 'main',
        component: () => import('@/pages/Index/MainPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/shifts',
        name: 'shifts.index',
        component: () => import('@/pages/Shift/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/impersonate',
        name: 'impersonate',
        component: () => import('@/pages/Index/ImpersonatePage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: 'Bosh sahifa',
        }
    },
    {
        path: "*",
        component: () => import('@/pages/Page/PageNotFound.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Cashier/LoginPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Kirish',
        }
    },
    {
        path: '/not-auth',
        name: 'not_auth',
        meta: {
            layout: 'content',
            requiresAuth: false,
            title: '401',
        },
        component: () => import('@/pages/Page/PageNotAllowed.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'privacy_policy',
        component: () => import('@/pages/Page/PrivacyPolicyPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: 'Maxfiylik siyosati',
        }
    },


]

export default routes