import axios from 'axios'

const state = {
    shift: {
        first_name: "",
        last_name: "",
        second_name: "",
        phone_number: "+998",
        phone_number2: "+998",
        region: {},
        district: {},
        mfy: {},
        street: "",
        flat: "",
    },
    shifts: {},
    shifts_loading: false,
    shifts_errors: {}
}

const getters = {
    shift: state => state.shift,
    shifts: state => state.shifts,
    shifts_loading: state => state.shifts_loading,
    shifts_errors: state => state.shifts_errors,
    shift_names: state => state.shift_names,

}

const mutations = {
    setShifts(state, shifts) {
        state.shifts = shifts
    },
    setShift(state, shift) {
        state.shift = shift
    },
    setShiftsLoading(state, loading) {
        state.shifts_loading = loading
    },
    setShiftsErrors(state, errors) {
        state.shifts_errors = errors
    },

}

const actions = {
    async getShifts({commit}, params) {
        commit('setShiftsLoading', true)
        commit('setShiftsErrors', {})
        try {

            let response = await axios.get(`/api/cashier/shifts`, {params})
            commit('setShifts', response.data)
            commit('setShiftsLoading', false)
            return true

        } catch (error) {
            commit('setShiftsLoading', false)
            commit('setShiftsErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}